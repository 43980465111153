.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-wrap: wrap;
}

.app_container {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  max-width: 100%;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

/* a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
} */

:root {
	overscroll-behavior: none;
}

.row>* {
  /* flex-shrink: 0;
  width: 100%; */
  max-width: 100%;
  /* padding-right: 0;
  padding-left: 0;
  margin-top: 0; */
  padding-right: calc(var(--bs-gutter-x)* .25);
  padding-left: calc(var(--bs-gutter-x)* 0);
  margin-top: var(--bs-gutter-y);
}

.root-container {
  /* display: flex; */
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.bottom-bar {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.draggable:empty {
  display: none;
}

.draggable {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: color 0.2s linear 0s, background-position 0.2s linear 0s, background-size 0.2s linear 0s, background 0.2s linear 0s;
  cursor: col-resize;
  height: 100%;
  width: 10px;
  margin-left: 0px;
  background-image: radial-gradient(rgba(0, 0, 0, 0.2) 0%, transparent 70%, transparent 100%);
  background-size: 50px 100%;
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

.erd_scroll_detection_container {
    visibility: hidden;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
}

.erd_scroll_detection_container_animation_active {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-name: erd_scroll_detection_container_animation;
  animation-name: erd_scroll_detection_container_animation;
}

@keyframes erd_scroll_detection_container_animation {
0% {
    opacity: 1;
}

50% {
    opacity: 0;
}

100% {
    opacity: 1;
}
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.side-box {
  position: absolute;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  width: 18%;
  height: 100%; /*730*/
  opacity: 1;
  transition: width 0.1s ease-out 0s, height 0.1s ease-out 0s, top 0.1s ease-out 0s, left 0.1s ease-out 0s, background 0.1s ease-out 0s, opacity 0.1s ease-out 0s, transform 0.1s ease-out 0s;
}

/* Media queries for responsiveness */
/* @media (max-width: 1400px) {
  .side-box {
    width: 233px;
  }
}

@media (max-width: 1200px) {
  .side-box {
    width: 220px;
  }
}

@media (max-width: 992px) {
  .side-box {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .side-box {
    width: 180px;
  }
}

@media (max-width: 576px) {
  .side-box {
    width: 150px;
  }
} */


.os-viewport {
  position: absolute;
  overflow: hidden;
  inset: 0px;
  padding: 0px;
  margin: 0px;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  outline: 0px !important;
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;  Firefox */
}
/* .os-viewport-native-scrollbars-invisible {
  scrollbar-width: none !important;
} */

/* .os-viewport::-webkit-scrollbar {
  display: none;
} */

.os-viewport-native-scrollbars-invisible#os-dummy-scrollbar-size, .os-viewport-native-scrollbars-invisible.os-viewport {
  scrollbar-width: none !important;
}

.os-viewport-native-scrollbars-invisible#os-dummy-scrollbar-size::-webkit-scrollbar, 
.os-viewport-native-scrollbars-invisible.os-viewport::-webkit-scrollbar, 
.os-viewport-native-scrollbars-invisible#os-dummy-scrollbar-size::-webkit-scrollbar-corner, 
.os-viewport-native-scrollbars-invisible.os-viewport::-webkit-scrollbar-corner {
  display: none !important;
  width: 0px !important;
  height: 0px !important;
  visibility: hidden !important;
  background: 0px 0px !important;
}

.os-content-glue {
  box-sizing: inherit;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.os-padding {
  box-sizing: inherit;
  direction: inherit;
  position: absolute;
  overflow: visible;
  padding: 0px;
  margin: 0px;
  inset: 0px;
  z-index: 1;
  width: auto !important;
  height: auto !important;
}

.os-content {
  direction: inherit;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  visibility: visible;
  box-sizing: border-box !important;
}

.os-content::before, .os-content::after {
  content: "";
  display: table;
  width: 0px;
  height: 0px;
  line-height: 0;
  font-size: 0px;
}

.css-17bgnci {
  overflow: hidden auto;
  height: 100%;
  padding: 20px;
}

.os-host, .os-host-textarea {
  position: relative;
  flex-flow: column;
  -webkit-box-pack: start;
  place-content: flex-start;
  align-items: flex-start;
  overflow: visible !important;
}

.os-host-end {
  position: relative;
  flex-flow: column;
  -webkit-box-pack: end;
  place-content: flex-end;
  align-items: flex-end;
  overflow: visible !important;
}

.os-host-end-resize-disabled {
  bottom: 0px;
}

.os-end-resize-observer, .os-end-resize-observer-host {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.os-host-overflow > .os-padding{
  overflow: hidden;
}

.os-host-overflow {
  overflow: hidden !important;
}

.os-resize-observer-host {
  padding: inherit;
  border-width: inherit;
  border-image: inherit;
  border-color: transparent;
  border-style: solid;
  box-sizing: border-box;
}

.os-resize-observer, .os-resize-observer-host {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.os-resize-observer-host > .os-resize-observer, .os-resize-observer-host::after {
  height: 200%;
  width: 200%;
  padding: inherit;
  border: inherit;
  margin: 0px;
  display: block;
  box-sizing: content-box;
}

.os-resize-observer-host::after {
  content: "";
}

.os-size-auto-observer {
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0px;
  margin: 0px;
  pointer-events: none;
  -webkit-box-flex: inherit;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0px;
  box-sizing: inherit !important;
}

.os-resize-observer {
  animation-duration: 0.001s;
  animation-name: animation-resize;
}

@keyframes animation-resize {
  0% {
    z-index: 0;
  }

  100% {
    z-index: -1;
  }
}



.os-scrollbar.os-scrollbar-unusable {
  background: 0px center;
}

.os-host-resize-disabled, .os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
  top: 0px;
  bottom: 0px;
}

.os-scrollbar-auto-hidden, .os-host-scrollbar-vertical-hidden > .os-scrollbar-vertical {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.os-scrollbar.os-scrollbar-unusable, .os-scrollbar.os-scrollbar-unusable * {
  pointer-events: none !important;
}

.os-host-transition > .os-scrollbar, .os-host-transition > .os-scrollbar-corner {
  transition: opacity 0.3s ease 0s, visibility 0.3s ease 0s, top 0.3s ease 0s, right 0.3s ease 0s, bottom 0.3s ease 0s, left 0.3s ease 0s;
}

.css-1wpdhyu {
  padding-bottom: 1.5rem;
}

.css-1wpdhyu > :first-child {
  margin-top: 0px;
}

.css-axrk4p {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
}

.css-1lsh4kf {
  font-size: 14px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  margin-right: 20px;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  min-height: 22px;
}

.css-logo {
  /* width: auto;
  height: auto; */
  display: block;
  /* max-width: 100%; */
}

.css-sidemenu {
  outline: 0px;
  margin-top: 16px;
}

.css-sideitems {
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

.css-sideitem {
  margin-top: 0px;
  margin-bottom: 20px;
}


.sidebar {
  /* min-width: 250px;
  max-width: 250px; */
  width: 20%;        /* Your default width */
  /* max-width: 25vw;     Max absolute width (not affected by zoom */
  background: #d8e3f6;  /* #D1DFE5 */
  color: #fff;
  /* margin-left: -250px; */
  transition: all 0.5s;
  /* height: 100vh; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  /* min-height: 100vh !important; */
  z-index: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

/* .sidebar-header {
  background: #D1DFE5;
} */

.sidebar-header h3 {
  color: #000000;
  padding: 0.6em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #6a8bc6;
}

.nav-item:hover {
  color: #7386d5;
  background: #a5c0f2;    /* #a1b3f9 */
}

.active {
  background-color: #6a8bc6; /* #6d7fcc */
}

.items-menu {
  color: #fff;
  background: #6a8bc6;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {
  /* height: calc(101.3vh); */
  height: 80vh; /*100vh*/
  overflow-y: scroll;
  background: #d8e3f6;   /* #D1DFE5 */
  /* max-height: 100vh; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* border-top: 1px solid rgb(243, 243, 243); 
  border-bottom: 1px solid rgb(243, 243, 243); */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.side-menu::-webkit-scrollbar {
  display: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.nav-container {
  position: absolute;
  z-index: 1;
  inset: 0px;
  width: 100%;
  height: 100%;
}
 
/* .side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466b3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3a4c99;
} */

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.main-box {
  position: absolute;
  box-sizing: border-box;
  top: 10px; 
  left: 18%;
  width: 82%;
  height: 100%;  /* 710px */
  opacity: 1;
  z-index: 9;
  /* transition: margin-left 0.1s ease; */
  /* -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease; */
  transition: width 0.1s ease-out 0s, height 0.1s ease-out 0s, top 0.1s ease-out 0s, left 0.1s ease-out 0s, background 0.1s ease-out 0s, opacity 0.1s ease-out 0s, transform 0.1s ease-out 0s;
}

/* Media queries for responsiveness */
/* @media (max-width: 1400px) {
  .main-box {
    margin-left: 233px;
  }
}

@media (max-width: 1200px) {
  .main-box {
    margin-left: 220px;
  }
}

@media (max-width: 992px) {
  .main-box {
    margin-left: 200px;
  }
}

@media (max-width: 768px) {
  .main-box {
    margin-left: 180px;
  }
}

@media (max-width: 576px) {
  .main-box {
    margin-left: 150px;
  }
} */

.css-main {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
}

.css-topbar {
  color: rgb(153, 153, 153);
  width: 100%;
  height: 75px;
  flex-shrink: 0;
  overflow: auto hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
  background: #fff;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  transition: transform 0.2s linear 0s;
  transform: translateY(0px);
}

.css-top-content {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  flex-shrink: 0;
  height: 75px;
  /* border-radius: 4px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.css-signout-container {
  display: flex;
  /* white-space: nowrap; */
  justify-content: center;
  align-items: center;
  flex-basis: auto;
  flex-shrink: 0;
  margin-right: 20px;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  max-height: 75px;
  background-color: transparent;
}

.navbar-noshadow {
  background-color: transparent; 
  box-shadow: none;
  padding: 0px;
  max-height: 75px;
}

.navbar-nav:last-child {
  box-shadow: none;
  padding: 0;
  /* margin-top: 1em; */
  align-items: center;
}

.css-main-content {
  position: absolute;
  overflow: auto;
  inset: 75px 0px 0px;
  z-index: 3;
  transition: all 0.1s linear 0s;
  height: calc(100% - 75px);
  background: transparent;
}

.css-content {
  background: rgb(255, 255, 255);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 10px 1rem;
  min-height: 100vh;
  box-sizing: border-box;
}

.css-container {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 10px;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  min-height: 95vh;
}

/* .css-bottombar {
  color: rgb(153, 153, 153);
  width: 100%;
  height: 75px;
  flex-shrink: 0;
  overflow: auto hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
  background: #fff;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: transform 0.2s linear 0s;
  transform: translateY(0px);
  -webkit-box-pack: end;
  place-content: flex-end;
  align-items: flex-end;
} */

/* .css-bottom-content {
  background: transparent;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  flex-shrink: 0;
  height: 100%; 
} */

/* .footer {
  background: #ffffff;
  width: 100%;
  min-height: 75px;
  text-align: center;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
  border-top: 3px solid rgba(0, 0, 0, 0.4);
} */

.footer {
  background-color: #333;
  color: #fff;
  /* background: #ffffff; */
  padding: 20px 0;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
  border-top: 3px solid rgba(0, 0, 0, 0.4);
}

.footer a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin: 10px 0;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-divider {
  border: 0;
  height: 2px;
  background: #cfcece;
  margin: 20px 0;
}

.span1-5 {
  line-height: 32px;
}

.content {
  /* padding: 20px; */
  /* margin-left: 0; */
  /* height: 100vh; */
  min-width: 0;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .side-box {
    width: 25%;
  }
  .main-box {
    margin-left: 0;
    position: absolute;
    box-sizing: border-box;
    top: 10px; 
    left: 25%;
    width: 75%;
    height: 100%;  /* 710px */
    opacity: 1;
    z-index: 9;
    transition: width 0.1s ease-out 0s, height 0.1s ease-out 0s, top 0.1s ease-out 0s, left 0.1s ease-out 0s, background 0.1s ease-out 0s, opacity 0.1s ease-out 0s, transform 0.1s ease-out 0s;
  }
}

@media only screen and (max-width: 768px){
  .datepicker-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .datepicker-container-report {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
  }

  .card-report-container {
    width: 100% !important;
  }

  .css-top-content {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: nowrap;
    flex-shrink: 0;
    height: 75px;
    padding: 0px 10px;
  }

  .cardtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 8em !important; 
  }
}

@media only screen and (max-width: 768px) {
  .side-box {
    transform: translateX(-100%);
  }
  .main-box {
    margin-left: 0;
    position: absolute;
    box-sizing: border-box;
    top: 0px; 
    left: 0px;
    width: 100%;
    height: 100%;  /* 710px */
    opacity: 1;
    z-index: 9;
    transition: width 0.1s ease-out 0s, height 0.1s ease-out 0s, top 0.1s ease-out 0s, left 0.1s ease-out 0s, background 0.1s ease-out 0s, opacity 0.1s ease-out 0s, transform 0.1s ease-out 0s;
  }

  .css-signout-container {
    display: flex;
    /* white-space: nowrap; */
    justify-content: center;
    align-items: center;
    flex-basis: auto;
    flex-shrink: 0;
    margin-right: 0;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    max-height: 75px;
    background-color: transparent;
  }
  .navbar-nav:last-child {
    box-shadow: none;
    padding: 0;
    margin-top: 1em;
    align-items: center;
  }

  .security_status {
    display: flex;
    justify-content: center;
    align-items: first baseline;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .security_status_l {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }

  .security-padding {
    padding-top: 10px;
  }
}

.dotted_rounded_rect {
  height: 250px;
  border: dashed 3px lightgray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_file_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.drag_drop_div {
  background-color: lightgray;
}
.upload_your_file {
  display: flex;
  flex-direction: row;
}
.loader {
  display: flex;
  width: 200px;
  height: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #5a68a8;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
}

.mytable {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  border-color: #000000;
}

.myth {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #ffffff;
  font-weight: 500;
  border-color: #000000;
}

.tableHeight {
  max-height: 600px;
  overflow-y: auto;
}

.bottombar {
  display: flex;
  position: relative;
  min-height: 75px;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(243, 243, 243);
}

.bottombar .child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottombar .childItem {
  padding-right: 20px;
  text-decoration: none;
  color: black;
  font-size: small;
}

.addtagbodycontainer {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
}

.addtaginput {
  margin-left: 10px;
  height: 35px;
  width: 70px;
}

.addtagnameinput {
  margin-left: 10px;
  height: 35px;
  width: 300px;
}

.addtagtitle {
  margin-top: 5px;
}
.addtagradio {
  display: flex;
  margin-top: 0px;
  margin-left: 20px;
}
.template {
  width: 220px;
}

.template_parent {
  height: 650px;
  overflow-y: scroll;
}

.mytemplate_box {
  display: flex;
  flex-direction: row;
}

.tagth {
  font-size: small;
  font-weight: bold;
}

.tabletagpage {
  max-height: 600px;
  overflow-y: auto;
}

.templateinput {
  margin-left: 10px;
  margin-top: 4px;
  height: 40px;
  width: 210px;
}

.appLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidemenuitem {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .table {
  border: 1px solid black;
}

.table thead th {
  border-top: 1px solid #000!important;
  border-bottom: 1px solid #000!important;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.table td {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: none!important;
} */
.security_status_p {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.security_status {
  display: flex;
  justify-content: center;
  align-items: first baseline;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
}

.security_status_l {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
}

.security_status_comment {
  margin: 10px;
}

.vulnerability_status {
  display: flex;
  justify-content: center;
  align-items: first baseline;
  flex-direction: row;
  margin: 10px;
}

.security_status_buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: 10px;
  gap: 15px
}

.datepicker-container {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.datepicker {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: flex-start;

}

.datepicker-label {
  flex-wrap: nowrap;
}

.nowrap {
  flex: 1 1 auto;
  margin-right: 5px;
  margin-top: 5px;
  white-space: nowrap;
  align-self: flex-start;
}

.cardtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 7em;
}

.mymenu-item {
  cursor: pointer;
  display: flex;
  -webkit-box-align: start;
  align-items: center;
  width: 100%;
  height: 60px; /*80px */
  font-size: 13px;
  text-decoration: none;
  color: rgb(51, 51, 51);
  background: transparent;
}

.side-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.img-arrow-container {
  display: flex;
  width: 100%;
  margin-left: 3em;
  justify-content: flex-end;
  padding-right: 1em;
}

.img-arrow {
  align-self: flex-end;
}

.arrow-text {
  white-space: nowrap;
}

.button {
  background-color: white;
  color: black;
  border: 2px solid #6a8bc6;
  border-radius: 8px;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #6a8bc6;
  color: white;
  border: 2px solid #6a8bc6;
  border-radius: 8px;
}

.button-back {
  background-color: #6a8bc6;
  color: white;
  border: 2px solid #5a68a8;
  border-radius: 8px;
  transition-duration: 0.4s;
}

.button-back:hover {
  background-color: white;
  color: black;
  border: 2px solid #6a8bc6;
  border-radius: 8px;
}

.button-body {
  background-color: #6375b9;
  color: white;
  border: 2px solid #5968a4;
  border-radius: 8px;
  transition-duration: 0.4s;
}

.button-body:hover {
  background-color: white;
  color: black;
  border: 2px solid #5968a4;
  border-radius: 8px;
}

.button-danger {
  background-color: #ce3e3e;
  color: white;
  border: 2px solid #a13131;
  border-radius: 8px;
  transition-duration: 0.4s;
}

.button-danger:hover {
  background-color: white;
  color: black;
  border: 2px solid #a13131;
  border-radius: 8px;
}

.input-border {
  border: 2px solid #6a8bc6;
}
.input-border:focus {
  border: 2px solid #6a8bc6;
}

table.table-bordered{
  border:1px solid #D1DFE5;
  margin-top:20px;
}
table.table-bordered > thead > tr > th{
  border:1px solid #D1DFE5;
}
table.table-bordered > tbody > tr > td{
  border:1px solid #D1DFE5;
}

.navbar-height {
  height: 80px;
}

.detail-label {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.search-container {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.save-rule-button-container {
  display: flex;
  justify-content: flex-end;
}

.modal-header-bg-color {
  background-color: #d8e3f6; /* D1DFE5 */
}
.access-denied {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.app-wrapper {
  display: flex;
  flex-direction: row;
}

.sidebar-new {
  position: sticky;
	top: 0;
	left: 0;
	height: 100vh;
}

#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.partner-detail-into {
  margin-left: 10px;
  font-weight: 400;
}
.partner-detail-update {
  display: flex;
  justify-content: flex-end;
}

.partner-integration-info {
  margin: 10px;
  font-weight: 400;
}
.icon-copy{
  background-image : url(./assets/copy.png);
  background-color: transparent;
  background-size: cover;
  border: none;
  display: inline-block;
  height: 32px;
  width: 32px;
}
.icon-reset{
  background-image : url(./assets/reset.png);
  background-color: transparent;
  background-size: cover;
  display: inline-block;
  border: none;
  height: 32px;
  width: 32px;
}
.icon-show{
  background-image : url(./assets/show.png);
  background-color: transparent;
  background-size: cover;
  border: none;
  display: inline-block;
  height: 32px;
  width: 32px;
}

.icon-hide{
  background-image : url(./assets/hide-eye.png);
  background-color: transparent;
  background-size: cover;
  border: none;
  display: inline-block;
  height: 32px;
  width: 32px;
}

.username {
  color: #000000;
  font-size: medium;
  font-weight: 500;
}

.user-email {
  color: #000000;
  font-size: small;
  font-weight: 300;
}

.signout {
  width: 30px; 
  height: 30px; 
  margin-left:10px; 
  cursor: pointer;
}

.datepicker-container-report {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
}

.btn-report-download {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
}

.redirect-container {
  color: #000000;
  cursor: pointer;
  font-size: medium;
  font-weight: 500;
}

.img-redirect {
  width: 20px;
  height: 20px; 
  margin-bottom: 5px;
}

.mobile-signout {
  width: 30px; 
  height: 30px; 
  margin-right:10px; 
  cursor: pointer;
}
.card-report-container {
  width: 60%;
}